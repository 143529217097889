/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import * as Yup from 'yup';
import editSaloonApi from '../../../apis/auth/editSaloonApi';
import getUserInfo from '../../../apis/auth/myInfoApi';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import PinIcon from '../../../common/icons/PinIcon';
import SaloonNameIcon from '../../../common/icons/SaloonNameIcon';
import UserIcon from '../../../common/icons/UserIcon';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import CustomMap from '../../../components/custom-map/CustomMap';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCountries from '../../../custom-hooks/useCountries';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import './SaloonSettingsForm.scss';

const SaloonSettingsForm = () => {
  const { i18n, t } = useTranslation();
  const generalLabelStr = (v) => t(`saloonSettingForm.${v}.label`);
  const generalrequiredErrStr = (v) =>
    t(`saloonSettingForm.${v}.errors.required`);
  const generalTypeErrorStr = (v) =>
    t(`saloonSettingForm.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`saloonSettingForm.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    saloonName_ar: Yup.string().required(
      generalrequiredErrStr('saloonName_ar')
    ),
    saloonName_en: Yup.string().required(
      generalrequiredErrStr('saloonName_en')
    ),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(10, generalMinErrorStr('phone', 10))
    // email: Yup.string()
    //   .required(generalrequiredErrStr('email'))
    //   .email(generalTypeErrorStr('email'))
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    // setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      saloonName_ar: '',
      saloonName_en: '',
      phone: '',
      country_id: '',
      city_id: '',
      saloonDescription_ar: '',
      saloonDescription_en: '',
      street_ar: '',
      street_en: '',
      neighborhood_ar: '',
      neighborhood_en: '',
      buildingNumber: '',
      saloonLat: '',
      saloonLng: ''
    }
  });

  const { user, setCurrentUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [selectedLocation, setSelecectedLocation] = React.useState({
    lat: '',
    lng: ''
  });
  const [selectedAddress, setSelectedAddress] = React.useState('');
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getUserInfo(user?.token),
        (res) => {
          if (checkRes(res)) {
            const data = res.data.data;
            setCurrentUser({
              ...data,
              token: user?.token,
              accountType: user.accountType,
              activated: user.activated
            });
          }
        },
        (error) => {
          errorNotification({
            title: t('errorTitle'),
            message: error?.response?.data?.message || t('errorMessage')
          });
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  const { allFetchedCountries } = useCountries();
  const [cancelCount, setCancelCount] = useState(0);
  // handle Initial values
  useEffect(() => {
    if (user?.saloon) {
      setValue('saloonName_ar', user?.saloon?.saloonName_ar || '');
      setValue('saloonName_en', user?.saloon?.saloonName_en || '');
      if (user?.phone && user?.countryCode) {
        setValue('phone', `${user.countryCode}${user.phone}`);
      } else {
        setValue('phone', user?.phone || '');
      }
      setValue(
        'saloonDescription_ar',
        user?.saloon?.saloonDescription_ar || ''
      );
      setValue(
        'saloonDescription_en',
        user?.saloon?.saloonDescription_en || ''
      );
      setValue('country_id', String(user?.saloon?.country_id || ''));
      setValue('city_id', String(user?.saloon?.city_id || ''));
      setValue('street_ar', user?.saloon?.street_ar || '');
      setValue('street_en', user?.saloon?.street_en || '');
      setValue('neighborhood_ar', user?.saloon?.neighborhood_ar || '');
      setValue('neighborhood_en', user?.saloon?.neighborhood_en || '');
      setValue('buildingNumber', user?.saloon?.buildingNumber || '');
      if (user?.saloon?.saloonLat && user?.saloon?.saloonLng) {
        setSelecectedLocation({
          lat: Number(user.saloon.saloonLat),
          lng: Number(user.saloon.saloonLng)
        });
      }
    }
  }, [user, cancelCount, allFetchedCountries]);

  // handle cancel
  const handleCancel = () => {
    setCancelCount((prev) => prev + 1);
  };

  const handleCityOptions = (countryId) => {
    if (countryId && allFetchedCountries?.length > 0) {
      const foundCountry = allFetchedCountries.find((c) => c.id == countryId);
      if (foundCountry && foundCountry.cities?.length > 0) {
        return foundCountry.cities.map((city) => ({
          title: city?.name,
          value: String(city?.id)
        }));
      }
    }
    return [];
  };

  const onSubmit = (data) => {
    // if (!watch('image') && !systemImgUrl) {
    //   setError('image', {
    //     type: 'required',
    //     message: 'اختار صورة البروفايل'
    //   });
    // }

    const formData = new FormData();

    if (data.saloonName_ar)
      formData.append('saloonName_ar', data.saloonName_ar);
    if (data.saloonName_en)
      formData.append('saloonName_en', data.saloonName_en);
    const formatedIntlPhone = formatPhoneNumberIntl(`${data.phone}`); // '+966 50 123 4444'
    const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    const countryCode = phoneStrArr[0];
    let phone = '';
    if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    else if (phoneStrArr?.length > 2) {
      let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
      phone = array.join('');
    }
    if (data.phone) formData.append('phone', phone);
    if (data.phone) formData.append('countryCode', countryCode);
    if (data.country_id) formData.append('country_id', data.country_id);
    if (data.city_id) formData.append('city_id', data.city_id);
    if (data.saloonDescription_ar)
      formData.append('saloonDescription_ar', data.saloonDescription_ar);
    if (data.saloonDescription_en)
      formData.append('saloonDescription_en', data.saloonDescription_en);
    if (data.street_ar) formData.append('street_ar', data.saloonDescription_ar);
    if (data.street_en) formData.append('street_en', data.saloonDescription_en);
    if (data.neighborhood_ar)
      formData.append('neighborhood_ar', data.neighborhood_ar);
    if (data.neighborhood_en)
      formData.append('neighborhood_en', data.neighborhood_en);
    if (data.buildingNumber)
      formData.append('buildingNumber', data.buildingNumber);
    formData.append(
      'saloonLat',
      selectedLocation?.lat ? selectedLocation.lat : '23.8859'
    );
    formData.append(
      'saloonLng',
      selectedLocation?.lng ? selectedLocation.lng : '45.0792'
    );

    setIsLoading(true);

    customApiRequest(
      editSaloonApi(user?.token, formData, i18n.language),
      (res) => {
        setIsLoading(false);
        if (checkRes(res)) {
          setFetchCount((prev) => prev + 1);
          successNotification({
            title: t('successTitle'),
            message: res?.data?.message || t('successMessage')
          });
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || t('errorMessage')
          });
        }
      },
      (error) => {
        setIsLoading(false);
        errorNotification({
          title: t('errorTitle'),
          message: error.res
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form saloon-settings-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-fields-wrap">
          <div className="row-form-wrapper">
            <AntdTextField
              name="saloonName_ar"
              type="text"
              placeholder={generalLabelStr('saloonName_ar')}
              label={generalLabelStr('saloonName_ar')}
              errorMsg={errors?.saloonName_ar?.message}
              validateStatus={errors?.saloonName_ar ? 'error' : ''}
              prefix={<SaloonNameIcon />}
              control={control}
            />
            <AntdTextField
              name="saloonName_en"
              type="text"
              placeholder={generalLabelStr('saloonName_en')}
              label={generalLabelStr('saloonName_en')}
              errorMsg={errors?.saloonName_en?.message}
              validateStatus={errors?.saloonName_en ? 'error' : ''}
              prefix={<SaloonNameIcon />}
              control={control}
            />
          </div>

          <div className="row-form-wrapper three">
            <div className={`country-code-phone-wrap ${i18n.dir()}`}>
              <p
                style={{
                  paddingBottom: 8
                }}
              >
                {generalLabelStr('phone')}
              </p>
              <Controller
                name="phone"
                control={control}
                // render={({ field: { onChange, onBlur, value, name, ref } }) => {
                render={({ field }) => {
                  return (
                    <PhoneInput
                      {...field}
                      placeholder="Enter phone number"
                      // value={phoneValue}
                      // onChange={setPhoneValue}
                      defaultCountry="SA"
                      className={`custom-phone-input ${i18n.language}`}
                    />
                  );
                }}
              />
              <p className="error-p">{errors?.phone?.message}</p>
            </div>

            <div className="select-label-wrap">
              <p className="label-p">{generalLabelStr('country_id')}</p>
              <div className="custom-select-wrap without-icon">
                <AntdSelectOption
                  name={`country_id`}
                  errorMsg={errors?.country_id && errors.country_id.message}
                  validateStatus={errors?.country_id ? 'error' : ''}
                  control={control}
                  setValue={setValue}
                  placeholder={generalLabelStr('country_id')}
                  prefix={<UserIcon />}
                  options={
                    allFetchedCountries?.length > 0 &&
                    allFetchedCountries.map((obj) => ({
                      title: obj?.name,
                      value: String(obj?.id)
                    }))
                  }
                  showSearch={true}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  formClassName="saloon-settings-form"
                />
              </div>
            </div>

            <div className="select-label-wrap">
              <p className="label-p">{generalLabelStr('city_id')}</p>
              <div className="custom-select-wrap without-icon">
                <AntdSelectOption
                  name={`city_id`}
                  errorMsg={errors?.city_id && errors.city_id.message}
                  validateStatus={errors?.city_id ? 'error' : ''}
                  control={control}
                  setValue={setValue}
                  placeholder={generalLabelStr('city_id')}
                  options={handleCityOptions(watch('country_id'))}
                  showSearch={true}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  formClassName="saloon-settings-form"
                />
              </div>
            </div>
          </div>

          <div className="row-form-wrapper">
            <AntdTextField
              name="neighborhood_ar"
              type="text"
              placeholder={generalLabelStr('neighborhood_ar')}
              label={generalLabelStr('neighborhood_ar')}
              errorMsg={errors?.neighborhood_ar?.message}
              validateStatus={errors?.neighborhood_ar ? 'error' : ''}
              prefix={<PinIcon />}
              control={control}
            />
            <AntdTextField
              name="neighborhood_en"
              type="text"
              placeholder={generalLabelStr('neighborhood_en')}
              label={generalLabelStr('neighborhood_en')}
              errorMsg={errors?.neighborhood_en?.message}
              validateStatus={errors?.neighborhood_en ? 'error' : ''}
              prefix={<PinIcon />}
              control={control}
            />
          </div>
          <div className="row-form-wrapper">
            <AntdTextField
              name="street_ar"
              type="text"
              placeholder={generalLabelStr('street_ar')}
              label={generalLabelStr('street_ar')}
              errorMsg={errors?.street_ar?.message}
              validateStatus={errors?.street_ar ? 'error' : ''}
              prefix={<PinIcon />}
              control={control}
            />
            <AntdTextField
              name="street_en"
              type="text"
              placeholder={generalLabelStr('street_en')}
              label={generalLabelStr('street_en')}
              errorMsg={errors?.street_en?.message}
              validateStatus={errors?.street_en ? 'error' : ''}
              prefix={<PinIcon />}
              control={control}
            />
            <AntdTextField
              name="buildingNumber"
              type="text"
              placeholder={generalLabelStr('buildingNumber')}
              label={generalLabelStr('buildingNumber')}
              errorMsg={errors?.buildingNumber?.message}
              validateStatus={errors?.buildingNumber ? 'error' : ''}
              prefix={<PinIcon />}
              control={control}
            />
          </div>

          <div className="row-form-wrapper">
            <div className="text-area-label-wrap">
              <p className="label-p">
                {generalLabelStr('saloonDescription_ar')}
              </p>
              <div className="text-field-wrap">
                <AntdTextarea
                  className="form-text-area white-bg"
                  name="saloonDescription_ar"
                  rows={5}
                  placeholder={generalLabelStr('saloonDescription_ar')}
                  errorMsg={errors?.saloonDescription_ar?.message}
                  validateStatus={errors?.saloonDescription_ar ? 'error' : ''}
                  control={control}
                  prefix={<SaloonNameIcon />}
                />
              </div>
            </div>
            <div className="text-area-label-wrap">
              <p className="label-p">
                {generalLabelStr('saloonDescription_en')}
              </p>
              <div className="text-field-wrap">
                <AntdTextarea
                  className="form-text-area white-bg"
                  name="saloonDescription_en"
                  rows={5}
                  placeholder={generalLabelStr('saloonDescription_en')}
                  errorMsg={errors?.saloonDescription_en?.message}
                  validateStatus={errors?.saloonDescription_en ? 'error' : ''}
                  control={control}
                  prefix={<SaloonNameIcon />}
                />
              </div>
            </div>
          </div>

          <CustomMap
            width="100%"
            height="400px"
            selectedLocation={selectedLocation}
            setSelecectedLocation={setSelecectedLocation}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            isDraggable={true}
            hasAutoComplete={true}
          />
        </div>

        <div className="save-cancel-btns">
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isLoading}
          >
            {t('saloonSettingForm.submit_btn.label')}
          </Button>
          <button onClick={handleCancel} type="button" className="cancel-btn">
            {t('saloonSettingForm.cancelBtn')}
          </button>
        </div>
      </div>

      {isLoading && <LoadingModal />}
    </Form>
  );
};

export default SaloonSettingsForm;
